import { ImageCarousel } from '@dmm/lib-react-ui-components';
import React from 'react';
import CarouselModal from '../../../components/CarouselModal';
import { BDPMainGalleryMobile } from './BDPMainGalleryMobile';
import LikedBoat from '../../../components/LikedBoat';
import get from 'lodash/get';

export const BDPMainGallery = ({
  breakpoint,
  isWorking,
  listing,
  cookies,
  url,
  myboats,
  showMoreBoats,
  show,
  carouselIndex,
  hideModal,
  carouselAddParams,
  handleOpenContactForm,
  openModalDealerLocator,
  openImmersiveTourModal,
  onClickPreviousNext,
  hideLikeButton,
  mediaItems,
  carouselButtons,
  openImmersiveTourModalHandler,
  hasImmersiveTour,
  handleCarouselItemClick,
  handleClickShare,
  enableThumbnails,
  carouselThumbnails,
  liked,
  onClickLike
}) => {

  if (breakpoint !== 'desktop') {
    return (
      <BDPMainGalleryMobile
        handleClickShare={handleClickShare}
        onClickPreviousNext={onClickPreviousNext}
        hasImmersiveTour={hasImmersiveTour}
        openImmersiveTourModalHandler={openImmersiveTourModalHandler}
        liked={liked}
        onClickLike={onClickLike}
      />
    );
  }

  return (
    <>
      <CarouselModal
        device={breakpoint}
        isLoading={isWorking}
        listing={listing}
        cookies={cookies}
        url={url}
        myboats={myboats}
        showMoreBoats={showMoreBoats}
        show={show}
        showThumbs={true}
        selectedCarouselItem={carouselIndex}
        hideModal={hideModal}
        adParams={carouselAddParams}
        breakpoint={breakpoint}
        handleOpenContactForm={handleOpenContactForm}
        openModalDealerLocator={openModalDealerLocator}
        openImmersiveTourModal={openImmersiveTourModal}
        liked={liked}
        onClickLike={onClickLike}
      />
      <div className="imageCarousel">
        <ImageCarousel
          previousOnClick={onClickPreviousNext}
          nextOnClick={onClickPreviousNext}
          hideLikeButton={hideLikeButton}
          items={mediaItems}
          externalHeartComponent={
            !hideLikeButton ? (
              <LikedBoat
                listing={listing}
                listingId={get(listing, 'aliases.boat-trader')}
                myboats={myboats}
                imtId={get(listing, 'aliases.imt')}
                cookies={cookies}
                url={url}
                liked={liked}
                onClickLike={onClickLike}
              />
            ) : null
          }
          buttons={carouselButtons}
          onClickImmersive={openImmersiveTourModalHandler}
          showImmersiveTour={hasImmersiveTour}
          showButtons={breakpoint === 'desktop'}
          handleCarouselItemClick={handleCarouselItemClick}
          onClickShare={handleClickShare}
          classNames={{ thumbnails: enableThumbnails }}
          thumbnails={enableThumbnails ? carouselThumbnails : null}
          onThumbnailClick={enableThumbnails ? onClickPreviousNext : null}
          shiftSliderOnClick={true}
          automaticVideoPlay={true}
          hideThumbnailControls={true}
        />
      </div>
    </>
  );
};

export default BDPMainGallery;
