import find from 'lodash/find';
import {HEALTHCHECK_URL_PATTERN} from '../../constants/healthcheck';
import {useContext, useMemo} from 'react';
import ABTestContext from '../../server/abTest';
import get from 'lodash/get';
import set from 'lodash/set';
import { isServer } from '../../store/utils';
import { kameleoonClient } from './kameleoon.client';
import { interestedInFinanceCheckboxExperiment } from '../../utils/trident/Experiments';
import {
  isSegmentCandidateForFinance,
  isSegmentCandidateForBrokerDealer,
  isSegmentCandidateForFinanceAdvantage,
} from '../../utils/trident/';

const isProduction = () => process.env.NODE_ENV === 'production' && process.env.REACT_APP_HOST.includes('www.boattrader.com');

export const getIsFinanceableBoatIndex = () =>{
  const experimentIndex = isProduction() ? 6 : 0;
  return experimentIndex;
};

export const getBuyerAccountLoginVariantIndex = () => {
  return isProduction() ? 11 : 4;
};

export const getIsBrokerDealerIndex = () => {
  return isProduction() ? 12 : 5;
};

export const getIsFinanceAdvantageSellerIndex = () => {
  return isProduction() ? 13 : 6;
};

export const getpreQualGoalIndex = () =>{
  const goalIndex = isProduction() ? 339593 : 339020;
  return goalIndex;
};

export const FEATURE_KEYS = {
  BOATS_SRP_AB_SORT: 'bt-srp-ab-sort',
  BOATS_BDP_UPDATE_PHASE4: 'bt-bdp-update-phase4',
  BOATS_SRP_MULTIVARIATION_SORT: 'bt-srp-multivariation-sort',
  BOATS_BDP_FINANCE_V2: 'bt_bdp_finance_v2_cta',
  BOATS_SRP_PRICE_SORT: 'bt-srp-ab-price-sort',
  BOATS_PREQUAL_FUNNEL_CTA: 'bt_prequal_funnel_cta',
  BOATS_BDP_BUYER_ACCOUNT_LOGIN: 'bt-fsbo-ps1062-buyeraccountlogin',
  BDP_INTERESTED_IN_FINANCE_CHECKBOX: 'bt-bdp-finance-checkbox',
  BOATS_SBP_VISIBILITY_LEVEL_THRESHOLD: 'bt-sbp-ab-visibility-level-threshold',
  BDP_LEAD_FORM_PHONE_REQUIRED: 'bt-bdp-lead-form-phone-required'
};

export const VARIATION_KEYS = {
};

export const CUSTOM_DATA_INDICES = {
  IS_FINANCEABLE_BOAT: getIsFinanceableBoatIndex(),
  BUYER_ACCOUNT_LOGIN: getBuyerAccountLoginVariantIndex(),
  IS_BROKER_DEALER: getIsBrokerDealerIndex(),
  IS_FINANCE_ADVANTAGE_SELLER: getIsFinanceAdvantageSellerIndex(),
};

const ignoreURLs = [
  HEALTHCHECK_URL_PATTERN
];

const advantageSortVariants = {
  // eslint-disable-next-line camelcase
  not_applicable: '0',
  off: '0',
  Reference: '0',
  on: '1'
};

export const updateAbTestFeatureFlagForClientSide = (featureFlagToUpdate, abTestConfiguration) => {
  if (isServer() || !process.env.REACT_APP_AB_SORT_EXPERIMENT_ID_TO_UPDATE_CLIENTSIDE){
    return false;
  }

  window?.Kameleoon?.API?.Experiments?.trigger(process.env.REACT_APP_AB_SORT_EXPERIMENT_ID_TO_UPDATE_CLIENTSIDE);

  const experiment = window?.Kameleoon?.API?.Experiments?.getById(process.env.REACT_APP_AB_SORT_EXPERIMENT_ID_TO_UPDATE_CLIENTSIDE);

  const associatedVariation = get(experiment, 'associatedVariation.name', 'off');
  const featureFlag = abTestConfiguration.find(fg => fg.key === featureFlagToUpdate);
  if (featureFlag){
    featureFlag.variation = associatedVariation;
  }
  else {
    abTestConfiguration.push({
      key: featureFlagToUpdate,
      variation: associatedVariation
    });
  }
};

export const addAbTestExperimentForGaTracking = () => {
  if (isServer() || !process.env.REACT_APP_AB_SORT_EXPERIMENT_ID_TO_UPDATE_CLIENTSIDE){
    return false;
  }
  const currentExperiments = get(window, '__AB_TEST__.abTestExperiments', false);
  if (currentExperiments) {
    const experimentFromKameleoon = window?.Kameleoon?.API?.Experiments?.getById(process.env.REACT_APP_AB_SORT_EXPERIMENT_ID_TO_UPDATE_CLIENTSIDE);
    const clientSideExperiment = currentExperiments.find(ex => `${ex.id}` === process.env.REACT_APP_AB_SORT_EXPERIMENT_ID_TO_UPDATE_CLIENTSIDE);
    if (experimentFromKameleoon && clientSideExperiment ) {
      clientSideExperiment.variation = `${get(experimentFromKameleoon, 'associatedVariation.id', 0) || 0}`;
    }
    else if (experimentFromKameleoon){
      currentExperiments.push({
        id: `${experimentFromKameleoon.id}`,
        variation: `${get(experimentFromKameleoon, 'associatedVariation.id', 0) || 0}`
      });
    }
  }
};

export const removeAbTestExperimentForGaTracking = () => {
  if (isServer() || !process.env.REACT_APP_AB_SORT_EXPERIMENT_ID_TO_UPDATE_CLIENTSIDE){
    return false;
  }
  let currentExperiments = get(window, '__AB_TEST__.abTestExperiments', []);
  if (currentExperiments.length){
    set(window, '__AB_TEST__.abTestExperiments', currentExperiments.filter(ex => {
      return ex.id !== process.env.REACT_APP_AB_SORT_EXPERIMENT_ID_TO_UPDATE_CLIENTSIDE;
    }));
  }
};

export const findFeatureFlagActive = (featureFlags, key, variation) => {
  const featureFlag = find(featureFlags, ['key', key]);
  if (variation && featureFlag?.variation !== variation) {
    return false;
  }
  return featureFlag;
};

export const isKameleoonEnabled = (req) => {
  return !!process.env.REACT_APP_KAMELEOON_SITE_CODE &&
    ignoreURLs.every((path) => !req.originalUrl.match(path)) &&
    (!req.headers['user-agent']?.toLowerCase().includes('bg-metrics') || req.headers['user-agent'] === 'BG-Metrics TAP BT Cypress');
};

export const useFeatureFlags = () => {
  const { abTestConfiguration } = useContext(ABTestContext);

  const featureFlags = useMemo(() => {
    const featureFlagBDPPhase4 = findFeatureFlagActive(abTestConfiguration, FEATURE_KEYS.BOATS_BDP_UPDATE_PHASE4);
    const featureFlagBDPLeadFormPhoneRequired = findFeatureFlagActive(abTestConfiguration, FEATURE_KEYS.BDP_LEAD_FORM_PHONE_REQUIRED);

    return { featureFlagBDPPhase4, featureFlagBDPLeadFormPhoneRequired };
  }, [abTestConfiguration]);

  return featureFlags;
};

export const getAdvantageSortVariant = (advantageSortFeatureFlag) => {
  if (!advantageSortFeatureFlag || !advantageSortVariants[advantageSortFeatureFlag.variation]) {
    return advantageSortVariants.off;
  }

  return advantageSortVariants[advantageSortFeatureFlag.variation];
};

export const preQualClickTracking = async () => {
  try {
    if (!window.KameleoonSDK) {
      return 'off';
    }

    const visitorCode = kameleoonClient.getVisitorCode();
    // Add new conversion data to client
    const conversionParameters = {
      goalId: getpreQualGoalIndex(),
      revenue: 0,
      negative: false
    };
    const conversion = new window.KameleoonSDK.Conversion(conversionParameters);
    await kameleoonClient.addData(visitorCode, conversion);

    // Get visitor feature flag variation key
    const flag = await kameleoonClient.getFeatureFlagVariationKey(
      visitorCode,
      FEATURE_KEYS.BOATS_PREQUAL_FUNNEL_CTA
    );
    return flag;
  } catch (error) {
    return 'off';
  }


};

export const fetchFinanceCheckboxExperiment = async (listing) => {
  try {
    if (!window.KameleoonSDK) {
      return 'off';
    }

    if (!interestedInFinanceCheckboxExperiment.isSegmentCandidate(listing)) {
      return 'off';
    }

    const visitorCode = kameleoonClient.getVisitorCode();

    // Send custom data to Kameleoon
    const isFinanceable = new window.KameleoonSDK.CustomData(CUSTOM_DATA_INDICES.IS_FINANCEABLE_BOAT, JSON.stringify(isSegmentCandidateForFinance(listing)));
    const isBrokerDealer = new window.KameleoonSDK.CustomData(CUSTOM_DATA_INDICES.IS_BROKER_DEALER, JSON.stringify(isSegmentCandidateForBrokerDealer(listing)));
    const isFinanceAdvantageSeller = new window.KameleoonSDK.CustomData(CUSTOM_DATA_INDICES.IS_FINANCE_ADVANTAGE_SELLER, JSON.stringify(isSegmentCandidateForFinanceAdvantage(listing)));
    const customData = [isFinanceable, isBrokerDealer, isFinanceAdvantageSeller];
    await kameleoonClient.addData(visitorCode, ...customData);

    const flag = await kameleoonClient.getFeatureFlagVariationKey(
      visitorCode,
      FEATURE_KEYS.BDP_INTERESTED_IN_FINANCE_CHECKBOX
    );
    return flag;
  } catch (error) {
    console.log(error);
    return 'off';
  }
};
